@import '../../../index.scss';

.left-bar{
    background-color: var(--box-background);
    width: 200px; /* Default width */
    padding: 20px 5px;
    height: 100vh; // Ensure it takes full height
    overflow-y: auto; // Allow vertical scrolling
    border-radius: 0px var(--border-radius) var(--border-radius) 0px;
    transition: width 0.3s ease;
    
    &.collapsed {
        width: 55px; /* Collapsed width */
    }
    
    @media screen and (max-width: 450px) {
        width: auto;
        padding-top: 0;
        height: min-content; // Adjust for mobile
    }
    h5{
        margin-left: 4px;
        color: var(--text-color-secondary);
    }

    svg{
        transform: scale(0.8);
        /* align center */
        color: var(--text-color-secondary);
    }
    
    .nav-link{
        width: 100%;
        min-width: max-content;
        margin: auto;
        position: relative;
        display: flex;
    }

    h6{
        margin-left: 10px; 
        margin-bottom: 10px;
        color: var(--text-color-secondary);
    }
    
    
    .toggle-div{
        position: relative;
        bottom: 0;
        display: flex;
        height: 30px;
        justify-content: flex-end;
    }

        
    .toggle-button {
        background-color: var(--background-color);
        border-radius: var(--border-radius);
        width: 30px;
        height: 30px;
        margin-right: -10px;
        float: right;
        position: relative;
        cursor: pointer;
        color: var(--text-color-secondary);
        transition: transform 0.3s ease;
        
        &.collapsed .icon {
            transform: rotate(180deg);
        }
    }

    .logo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        
        img {
            width: 40px;
            height: auto;
        }
    

        .icon {
            display: inline-block;
            transition: transform 0.3s ease;
        }
    }
}

.isActive{
    background-color: var(--background-color);
    border-radius:  var(--border-radius);
    h5{
        color : var(--text-color) !important;
    }

   svg{
        transform: scale(0.7);
        color : var(--text-color) !important;
    }
    @media screen and (max-width: 450px) {
        padding-top: 0;
        border-radius: 5px 5px 0px 0px;
        height: min-content;
    }
}

.title-leftBar{
    margin : 0px 0px;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    height: min-content;
    cursor: pointer;
    color : var(--text-color);
    h4{
        color : var(--text-color);
    }
}

.container-box-left-bar{
    display: grid;
    grid-template-rows: auto;
    padding: 10px 0px;

    @media screen and (max-width: 450px) {
        grid-template-columns: auto;
        grid-template-rows: none;
        padding : 0;
        padding-top : 15px;
    }

}

.logo-container{
    //border-bottom : 1px solid var(--border-color);
    padding: 4.5px;
    img{
        margin : auto;
        display: block;
        position: relative;
        width: 50px;
    }

    /* Mobile  */
    @media screen and (max-width: 450px) {
        display: grid;
        padding: 10px 5px;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        img{
                display: block;
                margin-left: 10px;
                position: relative;
                width: 40px;
            }

    }

 /* Mobile  */
    @media screen and (max-width: 350px) {
        img{
                display: none;
          
            }

    }
}



/* Tablet */
@media screen and (max-width: 768px) {

    .left-bar{
        width: 55px !important;
    }
    
    .left-bar-text{
        display: none !important;

    }
    .left-bar-icon{
        display: block !important;
        margin : auto;
        position: relative;
        align-items: center;
    }
    .icon-leftBar{
        display: block !important;
        position: relative;
        margin: auto;
    }
}


