header{
    /* justify the content to the space between */
    justify-content: space-between;
    /* align the items to the center */
    align-items: center;
    /* padding of 20px */
    padding: 30px 30px;
    /* divide in 3 columns */
    grid-template-columns: minmax(50px, 10%) auto minmax(100px, 10%);
    /* define grid type */
    display: grid;
    
    .logo {
        width: 48px;
        margin: auto;
        margin-top: 0px;
        position: relative;
    }

    .right {
        display: flex;
        align-items: center;
    }

    @media (max-width: 600px) {
        /* Adjust padding for mobile */
        padding: 20px;
        /* Stack items vertically on mobile */
        grid-template-columns: 1fr;
        text-align: center;
        
        .left, .right {
            display: none;
        }

        .logo {
            margin: 0 auto;
        }
    }
}

.full-page-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
