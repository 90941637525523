.page{
    width: 100%;
    height: inherit;
    display: block;
    height: 100vh; /* Make the page take up the full viewport height */
    padding: 20px; /* Add padding to all sides */
    position: relative;
}


