@import '../../../index.scss';

.skeleton-list{
    height: 100px;
    background: linear-gradient(90deg, var(--box-background), var(--background-color), var(--background-color));
    background-size: 200% 2100%;    
    border-radius: var(--border-radius);
}

.skeleton-list-thin{
    height: 20px;
    background: linear-gradient(90deg, var(--box-background), var(--background-color), var(--background-color));
    background-size: 200% 2100%;    
    border-radius: var(--border-radius);
}

.skeleton-square{
    height: 120px;
    width: 250px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    border-radius: var(--border-radius);
    background: linear-gradient(90deg, var(--box-background), var(--background-color), var(--box-background));
    background-size: 200% 2100%;

    -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 5s ease infinite;
    animation: Animation 5s ease infinite;

    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 100%;
        height: 300px;
        display: inline-block;
    }

}

@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.skeleton-parent-square{
    padding : 0px;
}

