.domain-container {
  
  table{
    width: 100%;
    border-collapse: collapse;
  }

  h2 {
    font-size: var(--h2);
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: var(--h5);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .ssl-section, .domain-section {
    margin-bottom: 40px;
  }

  .info-text {
    font-size: var(--h6);
    color: var(--primary-color);
  }

  .ssl-info {
    background-color: var(--box-background);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 16px;
    margin-bottom: 20px;
  }

  .ssl-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      font-size: var(--h3);
      font-weight: 600;
      margin: 0;
    }

    .ssl-type, .ssl-expiration {
      font-size: var(--h5);
      color: var(--text-color-secondary);
    }
  }

  .ssl-message {
    display: flex;
    align-items: center;

    .icon-check {
      color: var(--primary-color);
      margin-right: 10px;
    }

    p {
      margin: 0;
    }
  }

  .configure-ssl {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    font-size: var(--h5);
    font-weight: 600;
    cursor: pointer;
    float: right;
  }

  .domain-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .filter-domains {
      flex-grow: 1;
      margin-right: 10px;
      padding: 8px 12px;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      font-size: var(--h5);
    }

    .domain-filter {
      padding: 8px 12px;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      background-color: var(--background-color);
      font-size: var(--h5);
      margin-right: 10px;
    }

    button {
      padding: 8px 16px;
      border: none;
      border-radius: var(--border-radius);
      font-size: var(--h5);
      font-weight: 600;
      cursor: pointer;

      &.add-domain {
        background-color: var(--primary-color);
        color: var(--background-color);
      }

      &.refresh-acm {
        background-color: var(--background-color);
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
      }
    }
  }

  .domain-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    overflow: hidden;

    th, td {
      text-align: left;
      padding: 12px 16px;
      border-bottom: 1px solid var(--border-color);
    }

    th {
      background-color: var(--box-background);
      font-weight: 600;
      font-size: var(--h5);
      
      .dns-info {
        display: inline-block;
        position: relative;
        margin-left: 5px;
        cursor: help;

        .info-icon {
          color: var(--grey-color);
          font-size: 14px;
          vertical-align: middle;
        }

        .tooltip {
          visibility: hidden;
          width: 200px;
          background-color: var(--box-background);
          color: var(--text-color);
          text-align: center;
          border-radius: var(--border-radius);
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 125%;
          left: 50%;
          margin-left: -100px;
          opacity: 0;
          transition: opacity 0.3s;
          font-weight: normal;
          font-size: var(--h6);
          box-shadow: 0 2px 5px var(--shadow-color);
          border: 1px solid var(--border-color);
        }

        &:hover .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    td {
      font-size: var(--h5);
    }

    tr:last-child td {
      border-bottom: none;
    }

    .acm-status {
      display: flex;
      align-items: center;

      &.ok::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--primary-color);
        margin-right: 6px;
      }
    }

    .edit-icon {
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    max-width: calc(100vw - 80px);
    height: calc(100vh - 20px);
    background-color: var(--background-color);
    box-shadow: -2px 0 5px var(--shadow-color);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    margin: 10px;

    &.open {
      right: 0;
    }

    .sidebar-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid var(--border-color);

      h2 {
        margin: 0;
        font-size: var(--h2);
      }

      .close-button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: var(--text-color);
      }
    }

    .sidebar-body {
      flex-grow: 1;
      padding: 20px;

      label {
        display: block;
        margin-bottom: 5px;
        font-size: var(--h5);
        color: var(--text-color);
      }
    

      .info-text {
        font-size: var(--h6);
        color: var(--text-color-secondary);
      }

      .link {
        color: var(--primary-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .sidebar-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      border-top: 1px solid var(--border-color);

      button {
        margin-left: 10px;
        border-radius: var(--border-radius);
        padding: 8px 16px;
      }

      .primary {
        background-color: var(--primary-color);
        color: var(--background-color);
      }
    }
  }
}

.status-icon {
  margin-right: 5px;
  vertical-align: middle;
}

.status-icon.pending {
  color: #ffa500;
}

.status-icon.active {
  color: #00ff00;
}

.status-icon.inactive {
  color: #ff0000;
}

.edit-icon {
  cursor: pointer;
  color: #4a90e2;
}

.delete-icon {
  cursor: pointer;
  color: #ff4d4d;
  font-size: 1.2em;
}

.delete-icon:hover {
  color: #ff0000;
}