
.right-bar{
    overflow: hidden;
    height: auto;
    width: 100%;
    background-color : var(--background-color);
    @media screen and (max-width: 450px) {
        grid-template-rows: minmax(auto, 60px) minmax(auto, 60px);
    }
}


.main-page{
    display: flex;
    height: 100vh;
    width: 100%;
    
    .main-content {
        flex: 1;
        overflow-y: auto;
        padding: 20px;
    }
}
