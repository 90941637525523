.loading-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    .loading-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loading-gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .loading-gif {
        width: 50px;
        height: 50px;
        margin: auto;
    }
}
