@font-face {
  font-family: 'Title';
  src: url("../../public/fonts/Title.ttf");
}

@font-face {
  font-family: "Body";
  src: url("../../public/fonts/Body.ttf");
}

/* Fonts */
$main-font : "Title";
$title-font : "Body";


$color-theme : (
  primary-color : #7e83b3,
  background-color : #111111,
  background-color-inverse : #ffffff,
  secondary-color : #c8c8d7,
  tertiary-color : #504623,
  shadow-color : #000000,
  grey-color : #808080,
  
  primary-tints : (
    tint1: #1f1f1f,
    tint2: #3f4235,
    tint3: #5b604c
  ),
  
  secondary-tints : (
    tint1: #eeeefd,
    tint2: #fbfbff,
    tint3: #ffffff
  ),
  
  tertiary-tints : (
    tint1: #766c49,
    tint2: #837956,
    tint3: #9c926f
  ),

  red-color : #b23939,  
  green-color : #21c021,
  border-radius : 5px  
);

$h1 : 3.5rem;
$h2 : 1.8rem;
$h3 : 1.5rem;
$h4 : 1.2rem;
$h5 : 0.9rem;
$h6 : 0.8rem;

$themes: (
  dark: (
    color-mode: "dark",
    main-font: $main-font,
    title-font: $title-font,
    primary-color: map-get($color-theme, primary-color),
    secondary-color: map-get($color-theme, secondary-color),

    red-color: map-get($color-theme, red-color),
    green-color: map-get($color-theme, green-color),
    third-color: map-get($color-theme, tertiary-color),
    border-radius: map-get($color-theme, border-radius),
    background-color: map-get($color-theme, background-color),
    
    button-color: map-get(map-get($color-theme, secondary-tints), tint1),
    
    shadow-color: map-get($color-theme, shadow-color),
    input-background: map-get(map-get($color-theme, primary-tints), tint2),
    
    text-color: map-get(map-get($color-theme, secondary-tints), tint3),

    text-color-secondary: map-get(map-get($color-theme, secondary-tints), tint2),
    grey-color: map-get($color-theme, grey-color),
    color-placeholder: map-get(map-get($color-theme, secondary-tints), tint2),
    
    border-color: map-get(map-get($color-theme, primary-tints), tint3),
    box-background: map-get(map-get($color-theme, primary-tints), tint1),
    box-background-secondary: map-get(map-get($color-theme, primary-tints), tint2),
  
  ),
  light: (
    color-mode: "light",
    main-font: $main-font,
    title-font: $title-font,
    primary-color: map-get($color-theme, primary-color),
    secondary-color: map-get($color-theme, secondary-color),

    red-color: map-get($color-theme, red-color),
    green-color: map-get($color-theme, green-color),
    third-color: map-get($color-theme, tertiary-color),
    border-radius: map-get($color-theme, border-radius),

    background-color: map-get($color-theme, background-color-inverse),
    button-color: map-get(map-get($color-theme, primary-tints), tint1),
    
    shadow-color: map-get($color-theme, shadow-color),  
    input-background: map-get(map-get($color-theme, secondary-tints), tint1),

    text-color: map-get(map-get($color-theme, primary-tints), tint3),

    text-color-secondary: map-get(map-get($color-theme, primary-tints), tint2),
    grey-color: map-get($color-theme, grey-color),
    color-placeholder: map-get(map-get($color-theme, primary-tints), tint2),

    border-color: map-get(map-get($color-theme, secondary-tints), tint3),
    box-background: map-get(map-get($color-theme, secondary-tints), tint1),
    box-background-secondary: map-get(map-get($color-theme, secondary-tints), tint2),
  )
);

// Define default theme (light) variables globally
@each $color, $value in map-get($themes, 'dark') {
  :root {
    --#{$color}: #{$value};
  }
}

// Define dark theme variables under a specific class
.theme-dark {
  @each $color, $value in map-get($themes, 'light') {
    --#{$color}: #{$value};
  }
}
