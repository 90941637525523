.policies-container{

    .text-container{
        margin-top : 30px;
        padding: 20px 20%;
            margin-bottom : 50px;

        .text-grey{
            margin-top : 10px;
        }

        .text-primary{
        }
    }
}
