@import '../../../../index.scss';


@keyframes borderProgress {
  0% {
    border-image-source: linear-gradient(to right, var(--primary-color) 0%, transparent 0%);
  }
  100% {
    border-image-source: linear-gradient(to right, var(--primary-color) 100%, transparent 100%);
  }
}

.modal-create-app {
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, var(--primary-color) 0%, transparent 0%);
  animation: borderProgress 6s linear infinite;
  width: 60%;
  min-width: 600px;
  border-radius: var(--border-radius);
  height: 80%;
  min-height: 500px;
  display: grid;
  grid-template-rows: 30px 60px auto 50px;

  .button{
      margin-top : 10px;
  }
}

.modal-footer{
    position: relative;
    margin-top: 20px;
    justify-content: flex-end;
    justify-self: flex-end;

    .grid{
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
    }
   
    button{
        position: relative;
        justify-content: flex-end;
        justify-self: flex-end ;
        width: min-content;
    }
}