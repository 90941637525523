.tooltip-container {
    position: absolute;
    display: inline-block;
    background-color: var(--background-color);
    color: var(--text-color);
    border-radius: var(--border-radius);
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
