/* Implement the css classes of Stat/index.js */

// Path: webapp/client/src/app/components/Stat/index.js
.info-container {
    flex: 1 1 15%; /* Grow to fill the space, but don’t shrink below 20% of the container’s width */
 	min-width: 150px;
    box-sizing: border-box; /* Includes padding and border in the width */

	/* Add Space between items */


	.value{
		text-align: left;
	}

	.value-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}


}