.progress-bar{
    width: 100%;
    height: 5px;
    border-radius: var(--border-radius);
    background-color: var(--grey-color);
    position: relative;
    margin : 10px 0;
}

.progress-bar-fill{
    height: 100%;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    transition: width 0.5s ease-in-out;
}