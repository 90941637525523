footer{
    margin: auto;
    position: relative;
    width: 100%;
    margin-top: 50px;

    .payments-container{
        float: right;
        /* Aligns the cards to the right */
        justify-content: flex-end;
    }

    .container{
        max-width: 1200px !important;
    }

    .footer-content{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .sub-footer{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .links{
        display: flex;
        /* Gap between links */
        justify-content: flex-end;
        

        h5{
            color : var(--grey-color) !important;
            /* add a small dot between links */
            &::after{
                content: '•';
                margin: 0 5px;
            }       
        }

    }

    .socials{
        display: flex;
        gap: 10px;
    }
    
    .logo{
        width: 40px;
    }

    @media (max-width: 768px) {
        .footer-content {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .sub-footer {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .links {
            justify-content: center;

            h5 {
                &::after {
                    content: '';
                }
            }
        }

        .cards-container {
            justify-content: center;
            float: none;
        }

        .payments-container {
            justify-content: center; // Center the payment cards
            float: none; // Remove float
        }

        .logo {
            margin: 0 auto; // Center the logo
            margin-bottom: 10px;
        }
    }
    
    .payments-container,
    .sub-footer .links a:first-child,
    .sub-footer .links a:nth-child(2) {
        @media (max-width: 920px) {
            display: none;
        }
    }

    @media (max-width: 920px) {
      .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .socials {
        order: 2;
      }

      .logo {
        order: 1;
        display: none;
        /* center the logo */
        margin: 0 auto;
      }
    }
}
