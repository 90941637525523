@import '../../../index.scss';

.loading-container{
    position: fixed;
    height: 100vh;
  background-color: var(--background-color);
    width: 100vw;
    margin: 0;
    z-index: 1000;

    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 200px;
    }
}