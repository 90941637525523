.danger-zone {
    border: 1px solid var(--red-color);
    border-radius: var(--border-radius);
    margin-top: 20px;

    .button.danger {
        background-color: var(--red-color);
        color: var(--text-color);
        
        &:hover {
            background-color: var(--red-color);
        }
    }
    
    p {
        margin: 5px 0;
    }
}

.settings-grid {
    display: grid;
    gap: 24px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
}

.logo-section {
    h5 {
        color: var(--text-color-secondary);
    }

    .logo-container {
        .current-logo {
            display: flex;
            flex-direction: column;
            gap: 15px;
            
            .app-logo {
                width: 60px;
                height: 60px;
                border-radius: var(--border-radius);
                object-fit: contain;
                margin-right: 20px;
            }
        }

        .input-area {
            min-height: 120px;
            width: 100%;
            
            &:hover {
                border-color: var(--primary-color);
            }
        }
    }
}

.info-section {
    .input-group {
        margin-bottom: 20px;
    }

    .info-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .settings-grid {
        grid-template-columns: 1fr;
    }

    .logo-section {
        .logo-container {
            flex-direction: row;
            justify-content: center;
            
            .app-logo {
                width: 80px;
                height: 80px;
            }

            .button {
                width: auto;
            }
        }
    }

    .info-section {
        .info-grid {
            grid-template-columns: 1fr;
        }
    }
}