.cumulative-toggle {
    margin-bottom: 10px;
    padding: 8px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--primary-color-dark);
    }
}

.table-last-payments{
   height: 400px;
}