.bubble-graph {
    flex: 1; /* Allows the stat container to grow and fill available space */
    min-width: 200px; /* Ensures that it doesn’t shrink too small */
	width: fit-content;
    height: 400px;
    display: grid;
    position: relative;
	justify-content: space-between; // Add space between items
    grid-template-rows: 40px 250px auto;
    
  h4 {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .graph-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    position: relative;
  }
  
  .bubbles-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .bubble {
    position: absolute;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    text-align: center;
    transition: all 0.3s ease;
    transform: translate(-50%, -50%);

    &:hover {
      transform: translate(-50%, -50%) scale(1.05);
    }
    
    .bubble-percentage {
      font-size: 0.8em;
    }
  }

  .bubble-legend {
    display: flex;
    gap: 10px;
    padding-top : 10px;
    width: 100%;
    height: auto;
    position: relative;
    
    
    .legend-item {
      align-items: center;
      text-align: center;
      
      .legend-color {
        width: 40%;
        text-align: center;
        align-items: center;
        margin: 0 auto;
        height: 6px;
        background-color: var(--primary-color);
        color: var(--primary-color);
        border-radius: var(--border-radius);
      }
      
      .legend-label {
        margin-top : 8px;
      }
      
      .legend-value {
      }
    }
  }
}
