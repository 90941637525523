.toggle {
    background-color: var(--primary-color);
    color: var(--box-background§);
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--box-background);
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  margin-left: 10px;
  border-radius: var(--border-radius);
  border: 1px solid var(--box-background);
  opacity: 0.8;
  padding: 2px 10px;
}
