@import '../../../index.scss';

.modal-box{
    background-color: var(--background-color);
    position: fixed;
    box-shadow: 10px 1px 100px 1px var(--shadow-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    height: min-content;
    z-index: 10;
    padding : 20px;
    width: min-content;
    height: min-content;

}

.modal-middle{
    background-color: var(--background-color);
    /* Position middle of the screen */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
    box-shadow: 10px 1px 100px 1px var(--shadow-color); 
    border-radius: 5px;
    overflow: hidden;
    height: min-content;    
    z-index: 10;    

    .closeIcon{
        position: absolute;
        color : var(--grey-color); 
        background-color: var(--background-color);
        height: 20px;
        margin-left: 82%;
        margin-bottom: 20px;

        &:hover{
            cursor: pointer;
            /* Animation */
            transition: transform 0.2s ease-in-out;
            transform: scale(1.1);
        }
    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 100%;
        height: 100vh;
        overflow: scroll;
        margin: auto;

    }
}

.background-modal{
    height: 100vh;
    top: 0;
    position: absolute;
    opacity: 0.8;
    width: 100%;
    z-index: 3;
    background: black
}


.hideModal{
    display: none;
}