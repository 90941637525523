.linear-graph {
    min-width: 250px;

    .country-list {
        margin-top: 20px;
    }


    .image {
        width: 16px;
        /* Adjust size as needed */
        height: 16px;
        margin-right: 8px;
        /* Space between the icon and the text */
        vertical-align: middle;
        /* Aligns the icon with the text */
    }

    .source-name-container {
        display: block;
        position: relative;
        align-items: center;
    }

    .country-item {
        margin-bottom: 15px;
    }

    .country-info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .country-flag {
        margin-right: 10px;
        width: 24px;
    }

    .country-name {
        flex: 1;
    }

    .active-users {
        margin-right: 10px;
        font-weight: bold;
    }

    .percentage {
        width: 50px;
        text-align: right;
    }

    .graph-bar-container {
        position: relative;
        height: 8px;
        background-color: var(--background-color);
        border-radius: 4px;
        overflow: hidden;
    }

    .graph-bar {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;

        &.total-bar {
            width: 100%;
            background-color: var(--primary-color);
            opacity: 0.2;
        }

        &.source-bar {
            background-color: var(--primary-color);
            transition: width 0.3s ease;
        }

        &.secondary-bar {
            background-color: var(--secondary-color);
            transition: width 0.3s ease;
        }
    }

    .source-list {
        max-height: 300px;
        overflow-y: auto;
    }

    .source-image {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-top: 6px;
        /* align vertically with the text */
        display: block;
        background-color: var(--primary-color);
        padding: 2px;
        border-radius: 50%;
        position: relative;
    }

    .source-item {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .source-item-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .source-url {
        margin-top: 0px;
        color: var(--secondary-color);
        text-decoration: underline;
    }

    .source-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    .source-name {
        flex: 1;
        
    }

    .visitors {
        font-weight: bold;
    }

    .graph-bar-container {
        position: relative;
        height: 5px;
        width: 100%;
    }

    .graph-bar-container {
        position: relative;
        height: 8px;
        background-color: var(--background-color);
        border-radius: 4px;
        overflow: hidden;
    }
    
    .graph-bar {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    
        &.total-bar {
            width: 100%;
            background-color: var(--background-color);
        }
    
        &.source-bar {
            background-color: var(--primary-color);
            transition: width 0.3s ease;
        }
    
        &.secondary-bar {
            background-color: var(--secondary-color);
            transition: width 0.3s ease;
        }
    }
    
    .tooltip {
        position: absolute;
        background-color: var(--background-color);
        color: var(--text-color);
        border-radius: var(--border-radius);
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 10;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .source-item-container:hover .tooltip {
        opacity: 1;
    }

    .tooltip-container {
        position: absolute;
        background-color: var(--background-color);
        color: #fff;
        border-radius: 8px;
        padding: 12px 16px;
        width: 200px;
        box-shadow: 0 2px 5px var(--shadow-color);
        z-index: 10;
    }

    .tooltip-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--box-background);
    }

    .source-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    .tooltip-content {
        display: flex;
        flex-direction: column;
        text-align: left; // Align text to the left
    }

    .tooltip-row {
        display: flex;
        justify-content: space-between; // Space between label and value
        margin: 5px 0;
    }

    .tooltip-row span:last-child {
        text-align: right; // Align the value to the right
        flex: 1; // Allow the value to take up remaining space
    }

    .color-indicator {
        width: 10px;
        height: 10px;
        margin-top : 3px;
        left: 0;
        border-radius: 50%;
        margin-right: 8px;
    }
    
    .visitors {
        background-color: var(--primary-color);
    }

    .revenue {
        background-color: var(--secondary-color);
    }
}
