
/* Each individual stat container */
.stat-container {    
    flex: 1; /* Allows the stat container to grow and fill available space */
    min-width: 200px; /* Ensures that it doesn’t shrink too small */
	width: fit-content;
	margin-top : 0px;
	flex-direction: row; // Ensure items are in a row
	padding : 0px;
	justify-content: space-between; // Add space between items
	
	.tag-outlined{
		margin-left: 2px;
	}
	
	.title{
		width: 100%;
	}
	
	.box-container {
		background-color: var(--box-background);
		padding: 20px;
		border-radius: var(--border-radius);
		width: 100%; /* Ensures the box takes the full width of the stat container */
		height: 100%;
	}
	
	.value-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		
		.comparison-container{
			float: right;
			position: relative;
			display: block;
			position: relative;
			right: 0;

		}

	}

	.comparison-text.green {
		color: var(--green-color);
	}

	.comparison-text.red {
		color: var(--red-color);
	}

}
