.deployment-logs-container {
    position: fixed;
    right: 0;
    bottom: 20px;
    z-index: 1000;
    display: flex;
    align-items: stretch;
    transition: transform 0.3s ease;

    &.collapsed {
        transform: translateX(calc(100% - 40px));
    }
}

.deployment-logs {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 5px 0 0 5px;
    width: 400px;
    max-width: calc(100vw - 80px);
    height: 390px; // Changed from 400px to 390px
    max-height: calc(100vh - 50px); // Changed from calc(100vh - 40px) to calc(100vh - 50px)
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px var(--shadow-color);

    .logs-header {
        padding: 10px;
        background-color: var(--background-color);
        border-top-left-radius: 5px;
        border-bottom: 1px solid var(--border-color);

        h3 {
            margin: 0;
        }
    }

    .logs-content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px;
    }
}

.toggle-button {
    position: relative;
    left: -1px;
    width: 40px;
    background: #333;
    border: 1px solid #333;
    border-right: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 5px 0 0 5px;

    &:hover {
        background: #444;
    }

    .icon {
        font-size: 24px;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.deployment-logs-container {
    animation: slideIn 0.3s ease;
}