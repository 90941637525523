@tailwind base;
@tailwind components;
@tailwind utilities;
@import './config/colors.scss';

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color: var(--color-placholder);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: var(--color-placholder);
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: var(--color-placholder);
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: var(--color-placholder);
}
::-ms-input-placeholder { /* Microsoft Edge */
	color: var(--color-placholder);
}

::placeholder { /* Most modern browsers support this now. */
	color: var(--color-placholder);
}

#root, html {
	background-color: var(--background-color);
}

h1, h2 {
	font-family: var(--title-font);
}

h3, h4, h5, h6, span, body {
	font-family: var(--main-font);
}

h1, h2, h3, h4, h5, h6 {
	color: var(--text-color);
}

body {
	color: var(--text-color-secondary);
}

.hero {
	width: 90%;
	margin: auto;
	height: 1200px;
}

h1 {
	font-size: $h1;
}
h2 {
	font-size: $h2;
}
h3 {
	font-size: $h3;
}
h4 {
	font-size: var(--h4);
}

h5 {
	font-size: $h5;
}
h6 {
	font-size: $h6;
}
span {
	font-size: 0.75rem;
}
p {
	font-size: $h5;
}
body {
	font-size: 1rem;
}

.grey {
	color: var(--grey-color) !important;
}

.grey-2{
	color: var(--grey-color);
	opacity: 0.6;
}

.margin-bottom{
	margin-bottom: 10px;
}

.hover-item {
	&:hover {
		opacity: 0.7;
		/* Animation */
		transition: 0.3s;
	}
}

.divider {
	width: 100%;
	opacity: 0.3;
	position: relative;
	display: block;
	height: 1px;
	background-color: var(--grey-color);
}

.margin{
	margin-top : 20px;
	margin-bottom: 20px;
}

.small-margin{
	margin-top : 10px;
	margin-bottom: 10px;
}

.link {
	color: var(--primary-color);
}

.box-background{
	background-color: var(--box-background);
}

.container {
	background-color: var(--box-background);
	padding: 10px 20px;
	border-radius: var(--border-radius);
	display: grid;
	width: inherit;
	height: fit-content;
	margin : 10px auto;
	grid-template-columns: 100%;
}

.tag-outlined{
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	padding: 2px 5px;
	color: var(--text-color-secondary);
	width: fit-content;
}

.text-center{
	text-align: center;
	margin: auto;
	position: relative;
	display: block;
}



.fifty {
	display: grid;
	grid-template-columns: 50% 50%;
	
	.left{
		/* Align left */
		justify-content: left;	
		align-self: flex-start;
		float: left;
	}

	.right{	
		justify-content: right;
		float: right;
		text-align: right;
	}
}




.total-page{
	position: absolute;
	width: 100%;
	height: 100vh;
	background-color: var(--background-color);
}

.center{	
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.container-center{
	position: relative; /* Sets the position context to the container itself */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100%; /* Full height of the parent */


}

.full-width {
	width: 100%;
  }

.box-container{
	/* 	display: flex;
    flex-wrap: wrap; */
	
	background-color: var(--box-background);
	padding: 10px 20px;
	border-radius: var(--border-radius);
	width: fit-content;
	min-width: fit-content;
	position: relative;
	display: inline-block;
	height: auto;
	width: 100%;
	grid-template-columns: 100%;

	&.padded{
		padding: 20px;
	}
}

.scrollable-table{
	overflow-x: auto;
	width: 100%;
	min-width: 400px !important;
}

.space{
	margin-top: 10px;	
}

.flex {
	display: flex;
    justify-content: space-between;
    flex: 1; /* Make the main content take up the remaining space */
	gap: 20px; /* Adds space between the stat containers */
	flex-direction: row;
	height: auto;
	position: relative;
}

.justify-end{
	justify-content: flex-end;	
	/* position to the right */
	position: relative	;
	right: 0;
}


.min-width{
	width : fit-content;
}


svg{
    transform: scale(0.7);
}


.active{
	svg, h1,h2,h3,h4,h5,h6,p,span,body{
		color: var(--text-color) !important;
	}
}

img{
	&.small{
		width: 60px;
	}

}


