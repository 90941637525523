
.upload-logo-container{
    

    .logo{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        /* Align Middle */
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;    

    }
}