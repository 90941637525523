@import '../../../index.scss';


.error-tab{
    position: absolute;
    bottom: 6vh;
    right: 1vh;
    display: block;
    width: 270px;
    z-index: 2;

    height: auto;
    padding: 5px 10px;
    background-color: var(--box-background);
    border: 1px solid  var(--box-background);
    border-radius: 5px;

    svg{
        color : var(--grey-color);
        float: left;
        scale: 0.7;
    }

    h5{
        margin-left: 10px;
        margin-top : 2px;
    }

    .content{
        margin-top : 10px;
    }
}