.addon-page {
    padding: 20px;
    padding-bottom: 100px;
    overflow-y: auto; /* Allow vertical scrolling */
    height: 100vh; /* Full viewport height */

    button{
        align-items: flex-start;
        margin-left: 0px;
        float: left;
    }

    .status-bar{
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        max-width: 300px;
        width: fit-content;
    }
    .addon-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .addon-logo {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: var(--border-radius);
            margin-right: 15px;
        }
    }

    .addon-url {
        display: inline-block;
        margin-bottom: 20px;
        color: #007bff;
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }

    .env-variables-box {
        padding: 15px;
        max-width: 100%; /* Full width */
        display: flex;
        flex-wrap: wrap; /* Allow wrapping */
        gap: 20px; /* Space between items */
        
        ul {
            list-style-type: none;
            padding-left: 0;
            display: flex;
            flex-wrap: wrap; /* Allow wrapping */
            gap: 20px; /* Space between items */
            width: 100%; /* Full width for the list */

            li {
                flex: 1 1 calc(50% - 20px); /* Two items per row */
            }
        }
    }

    .addon-price{
        border: 1px solid var(--border-color);
        width: fit-content;
        border-radius: var(--border-radius);
        margin-top: 5px;
        padding: 0px 5px;
    }

    .addon-status-box {
        margin-bottom: 15px;
        max-width: 200px;
        display: flex;
        width: fit-content;
        gap: 10px;
        justify-content: space-around;
    }
    
    .status-item {
        border-radius: var(--border-radius);
        padding : 2px 5px;
        margin-top : 4px;
        display: flex;
        width: fit-content;
        align-items: center;
    }

    .status-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;

        &.green {
            background-color: #4CAF50;
        }

        &.red {
            background-color: #F44336;
        }
    }
}
