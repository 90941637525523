

.brand-picker-box{
    /* make box the size min witdth */
    min-width: 350px;
    width: min-content;
    
    .title-container{
        display: grid;
        grid-template-columns: auto 120px;
        min-width: 350px;
        width: min-content;
        
        button{
            grid-template-columns: auto auto;
            display: grid;
            max-width: fit-content;
            /* Align button to right */    
            justify-self: flex-end;
            justify-content: flex-end;
            

            svg{
                color : var(--grey-color);
                scale: 0.8;
                margin: 0;
                padding: 0;
            }

            h5{
               
            }
        }
    }

   
}

.app-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    min-width: 350px;
    width: fit-content;
    border-radius: var(--border-radius);
    border: 2px dotted var(--border-color);
    background-color: var(--box-background) ;
    transition: background-color 0.3s;
    margin-bottom: 10px;

    &.new-app{
        background-color: var(--background-color);
    }
    
    &:hover {
        background-color: var(--primary-color);
    }

    .app-logo {
        width: 60px;
        height: 60px;
        border-radius: var(--border-radius);
        object-fit: contain;
        margin-right: 20px;
    }

    .app-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h4 {
            margin: 0 0 5px 0;
        }

        .domain {
            color: var(--text-color-secondary);
        }
    }
}

.status-icon {
  font-size: 1.2em;
}

.status-icon.pending {
  color: #ffa500;
}

.status-icon.active {
  color: #00ff00;
}

.status-icon.inactive {
  color: #ff0000;
}

.edit-icon {
  cursor: pointer;
  color: #4a90e2;
}