$active-color: var(--primary-color);
$inactive-color: var(--text-color);
$background-color: var(--box-background);

.step-process {
  background-color: $background-color;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .circle {
      width: 48px;
      height: 48px;
      border: 2px solid $inactive-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $background-color;
      transition: border-color 0.3s ease;
    }

    .step-icon {
      font-size: 24px;
      color: $inactive-color;
      transition: color 0.3s ease;
    }

    &.completed {
      .circle {
        border-color: $active-color;
      }
      .step-icon {
        color: $active-color;
      }
    }

    &.active {
      .circle {
        border-color: transparent;
      }
      .step-icon {
        color: $active-color;
        animation: wiggle 0.5s ease-in-out infinite;
      }
    }

    &.future {
      .circle {
        opacity: 0.5;
      }
      .step-icon {
        opacity: 0.5;
      }
    }

    .rotating-border {
      position: absolute;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid $active-color;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
    }
  }

  .connector-line {
    flex-grow: 1;
    height: 2px;
    background-color: $inactive-color;
    transition: background-color 0.3s ease;

    &.completed {
      background-color: $active-color;
    }

    &.future {
      opacity: 0.5;
    }
  }

  .current-step-label {
    color: $inactive-color;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;

    img{
        align-items: center;
        margin: auto;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
   100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.7; }
  50% { opacity: 1; }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
}
