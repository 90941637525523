.builds-info {
    padding: 10px;
    .builds-item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: var(--border-radius);
        background-color: var(--box-background);
        margin-bottom: 10px;

        .builds-icon {
            margin-right: 10px;
            font-size: 24px;
            color: #ccc;
            
            &.deployed {
                color:  var(--primary-color);
            }
            
            &.succeeded {
                color: #28a745;
            }
            
            &.failed {
                color: #dc3545;
            }
        }

        .builds-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .builds-header {
                display: flex;
                align-items: baseline;
                margin-bottom: 2px;

                .builds-email {
                    font-weight: bold;
                    margin-right: 5px;
                }

                .builds-status {
                    &.deployed {
                        color: #4a4a4a;
                    }
                    
                    &.succeeded {
                        color: #28a745;
                    }
                    
                    &.failed {
                        color: #dc3545;
                    }
                }
            }

            .builds-details {
                display: flex;
                align-items: center;
                color: #6c757d;
                font-size: 0.9em;

                .builds-time {
                    margin-right: 10px;
                }

                .builds-version {
                    margin-right: 10px;
                    padding: 2px 5px;
                    background-color: var(--background-color);
                    border: 1px solid var(--border-color);
                    border-radius: 3px;
                }

                .builds-actions {
                    a {
                        color: var(--primary-color);
                        text-decoration: none;
                        margin-right: 10px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}


.builds-info {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.builds-list {
    flex: 1;
    overflow-y: auto;
}