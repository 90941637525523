.application-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
}

.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 5px;
}

.repo-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.repo-info .branch {
    background-color: var(--grey-color);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
}

.actions {
    display: flex;
    gap: 10px; /* Space between buttons */
}

.star-button, .open-app-button, .more-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.star-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.star-button svg {
    color: #ffc107;
}

// New styles for the deploy button
.actions .deploy-button {
    background-color: #28a745; // Example color for deploy button
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        background-color: #218838; // Darker shade on hover
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between text and icon */
    cursor: pointer;
    border: none;
    color: var(--background-color);
    transition: background-color 0.3s;
    width: auto; /* Fit button to content */
}

.button.outline {
    border: 1px solid #ccc;
    background-color: transparent;
}

.button:hover {
    background-color: #e0e0e0;
}

.loading-gif {
    width: 20px;
    height: 20px;
}

.button h5 {
    margin: 0;
    flex-grow: 1; /* Allow text to take up available space */
    text-align: left; /* Align text to the left */
}

.button svg {
    margin-left: 8px; /*  between text and icon */
}