@import '../../../index.scss';

.inputTextDiv{
    width: 100%;
    margin : 0px auto;

    svg{
        cursor: pointer;
        color : var(--primary-color);
        transition: transform 0.3s ease;
        transform: scale(1);
        margin-top : -3px;
    }
    input {
        width: 100%;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 16px;
        background-color: var(--background-color);
        border: 1px solid var(--border-color);
        margin-bottom: 5px;
        color: var(--text-color);

        &:focus {
            border-color: var(--primary-color);
            outline: none;
        }
    }

    h5{
        padding: 5px;
        color : var(--text-color-secondary);
    }
}

.input-error{
    display: block;
    position: relative;
    color : var(--red-color) !important;
}


.error-box{
    border : 1px solid var(--red-color) !important
}

.error-text{
    color :var(--red-color) !important
}


.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    input {
        width: 100%;
        padding: 5px 40px 5px 10px; // Add padding to the right for the icon
        font-size: 16px;
        background-color: var(--background-color);
        color: var(--text-color);
        border-radius: 5px;
        border: 1px solid var(--border-color);
        margin-bottom: 5px;
        transition: background-color 0.3s ease, opacity 0.3s ease;

        &:focus {
            border-color: var(--primary-color);
            outline: none;
        }
    }

    .edit-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
        font-size: 20px;
        color: var(--primary-color);
        z-index: 1;
    }

    &.disabled {
        input {
            background-color: var(--box-background);
            opacity: 0.7;
        }
    }

    &.editing {
        .edit-icon {
            transform: rotate(360deg);
            opacity: 0;
        }

        input {
            background-color: var(--background-color);
            opacity: 1;
        }
    }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

