.bread-container{
    display: inline-flex;
    position: relative;
    gap: 5px;
    height: fit-content;

    @media screen and (max-width: 450px){
        display: none;
    }

    span{
        font-size: var(--h4);
        color: var(--grey-color);
    }
    
    .current{
        width: fit-content;
        opacity: 0.7;
        border-radius: var(--border-radius);
    }

    .previous{
        width: fit-content;
        &:first-child{
        }
        color: var(--grey-color) !important;
        border-radius: var(--border-radius);
        margin: auto auto;
        text-align: center; 
        
    }
    
    .separator{
        position: relative;
        display: block;
        /* align vertically */
        /* center horizontally */
        margin: auto auto;   
        border-radius: 10px;

    }
}