.app-create-container{
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--shadow-color);

    .box-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 8px;
        background-color: var(--box-background);
    }
    
}

img{
    width: 30px;
}

.form-section {
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.input-section, .upload-section {
    flex: 1;
    display: flex;
    flex-direction: column;
}


.small-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}