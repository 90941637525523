.tabs-container {
    .tabs {
        display: flex;
        .tab {
            border-radius: 0;
            cursor: pointer;
            padding: 10px 20px;
            opacity: 0.6;
            transition: opacity 0.3s, border-bottom 0.3s;
            background: none; // Remove background
            border: none; // Remove border
            &.active {
                opacity: 1;
                border-bottom: 2px solid var(--border-color); // Add border-bottom when active
            }
        }
    }
    .tab-content {
        .tab-pane {
            animation: fadeIn 0.5s;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}