@import '../../../index.scss';

.button{
    width : 100%;
    margin: 5px auto;
    background-color: var(--button-color);
    opacity : 300ms ease-in-out 0s;
    border-radius: var(--border-radius);
    border: 0px;
    cursor:pointer;
    transition: box-shadow var(--duration-short) ease;
    -webkit-appearance: none;
    appearance: none;   
    text-align: center;
    padding : 4px 10px;
    
    &.padded{
        padding: 10px 20px;
    }
    
    svg{
        margin-top : 2px;
    }

    &.no-bg{

        h1,h2,h3,h4,h5,h6,span,body,p{
            color: var(--grey-color);
            text-align: center;
        }
        background-color: transparent !important;
    }

    &:hover{
        box-shadow: 0px 0px 10px 0px var(--shadow-color);
        opacity: 0.7;
        /* Animation */
        transition: opacity 0.3s ease;  
    }
    
    h1,h2,h3,h4,h5,h6,span,body,p{
        color: var(--background-color);
        text-align: center;
    }

    &.primary{
        background-color: var(--primary-color);
        color: var(--text-color);

    }
    
    &.outline{
        background-color: transparent;
        border: 1px solid var(--border-color);
        color: var(--text-color);
        padding-top : 0px;

        &:hover{
            background-color: var(--background-color);
            color: var(--text-color);
        }
    }

    &.icon-button{
        text-align: center;
        min-width: 30px;
        svg{
            margin : auto;
        }
    }
}


.fit{
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    align-self: flex-end;

}

.loading-circle {
    border: 4px solid var(--background-color);
    border-left-color: var(--primary-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}