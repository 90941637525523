@import '../../../index.scss';


.google-container{
    margin: 20px auto;
    width : fit-content;
    align-content: center;
    display: flex;
    position: relative;
}

.google-button{
    width: 100% !important;
    align-items: center;
    display: block;
    position: relative;
    text-align: center !important;
    padding: 0px 20px !important;
    border-radius: 50px !important;
    border : 1px solid var(--border-color) !important;
    box-shadow: none !important;
}

span{
    font-family: $main-font !important;
}