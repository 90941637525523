/* Generate Table scsss */

.table {
    display: block;
    position: relative;
    border-spacing: 0;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    .avatar {
        width: 32px;
        height: 32px;
        background-color: var(--primary-color);
        border-radius: 50%;
    }
}

.table-container {
	width: 100%;
    border-radius: var(--border-radius);
	overflow-x: auto; // Enable horizontal scrolling
}

table {
	width: 100%;
	border-collapse: collapse;
}


td, th {
	padding: 12px; /* Increase padding for better spacing */
	text-align: left; /* Center align text */
	border-bottom: 1px solid var(--background-color);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
    
    &.td-center {
        text-align: center;
    }
}

td:hover, th:hover {
	background-color: var(--hover-color); /* Change background on hover */
}

.status-icon {
    width: 16px; /* Adjust size for better visibility */
    height: 16px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle; /* Align vertically in the cell */
}

.status-icon.success {
    background-color: var(--primary-color); /* Primary color background */
    padding : 5px;
}

.status-icon.pending {
    color: var(--border-color); /* Grey background */
}

.status-icon.error {
    background-color: var(--red); /* Red background */
}


.country-flag {
    width: 24px; /* Adjust size for better visibility */
    height: 24px;
    display: inline-block; /* Ensure proper alignment */
    vertical-align: middle; /* Align vertically in the cell */    
}

.name-cell {
    text-transform: capitalize;
}

.status-container {
    align-items: center;
    text-align: center;
}

/* Active Icon should be green, and non active grey and late payment red */
.status-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* align self horizontally */   
    /* align to the center of the cell */
    /* align vertically and horizontally */
    align-items: center;
    text-align: center;
    margin: auto;
    vertical-align: middle;

    &.active {
        background-color: var(--green-color);
    }

    &.inactive {
        background-color: var(--red-color);
    }

    &.no-subscription {
        background-color: var(--grey-color);
    }
}
