@import '../../../index.scss';



.community-icon{
    border-radius: 50%;
    width: 30px;
    position: absolute;
    height: 30px;
    margin-left: 0px;
    float: left;
    border : 2px solid var(--border-color);

}

.container-communtiy-ico{
    width: 200px;

}

.rating-container{
    width: 200px;

    svg{
        display: inline-block;
    }
}