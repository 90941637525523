.page {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 80px;
    position: relative;
    display: block;
    height: 100%;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #007bff;
    color: white;
}

.tab:hover {
    background-color: #ddd;
}

.space {
    justify-content: space-between;
}

.main-content {
    display: flex;
    justify-content: space-between;
    flex: 1; /* Make the main content take up the remaining space */
}

.main-content > .tabs-container {
    flex: 1;
    overflow-y: auto; /* Allow scrolling if content overflows */
}

.main-content > .deployment-logs {
    flex: 0 0 300px; /* Adjust width as needed */
    margin-left: 20px;
    height: 100%; /* Make the logs container take up the full height */
    overflow-y: auto; /* Allow scrolling if logs overflow */
}

.deploy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deploy-header h4 {
    flex-grow: 1;
}

.deploy-header .button {
    margin-left: auto;
}

.loading-gif {
    width: 20px;
    height: 20px;
}