  
  .country-list {
    overflow-y: auto;
    height: 100%;
    min-width: fit-content;
  }
.world-graph {
    display: flex;
    height: 600px;
    width: 70%;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content !important;
    
    .graph-wrapper {
        width: inherit;
    }
    
    .graph-content {
        margin-left: -60px;
        
    }
  
  
  .country-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    right: 0;
    position: relative;
    padding: 5px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &.highlighted, &:hover {
      background-color: var(--hover-color);
    }

    &.total {
      font-weight: bold;
      margin-bottom: 15px;
      padding-bottom: 10px;
    }
  }

  .country-flag {
    margin-right: 10px;
    width: 24px;
  }
  
  .country-info {
    display: flex;
    width: fit-content;
    margin-left: 35px;
    justify-content: space-between;
    align-items: center;
  }
  
  .country-name {
    flex: 1;
  }

  .active-users {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
  }

  .percentage {
    width: 50px;
    text-align: right;
  }
  
  .percentage-bar {
    position: absolute;
    margin-bottom: -5px;
    left: 0;
    bottom: 0;
    height: 3px;
    transition: width 0.3s ease;

    &.total-bar {
      background-color: var(--background-color);
      z-index: 1;
    }
    
    &.country-bar {
      background-color: var(--primary-color);
      z-index: 2;
    }
  }

  .highlighted .percentage-bar, .country-item:hover .percentage-bar {
    height: 5px;
  }
}
