/* ... (keep existing styles) */

.delete-icon {
  cursor: pointer;
  color: var(--red-color); /* Lighter red color */
  font-size: 1.2em;
}

.delete-icon:hover {
  color: var(--red-color); /* Slightly darker on hover */
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--box-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color:var(--background-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-actions {
  margin-top: 20px;
}

.modal-actions button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.confirm-button {
  background-color: var(--red-color);
  color: var(--text-color);
}

.cancel-button {
  background-color: var(--background-color);
  color: var(--text-color);
}