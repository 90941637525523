.modal-footer{
    button{
        margin-right: 10px;

        &:last-child{
            margin-right: 0;
        }
    }
}

.modal-middle.modal-box.modal-create-app {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.form-content {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 20px;
}

.form-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    button {
        margin-left: 10px;
    }
}