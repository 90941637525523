.input-box {
    h4 {
        margin-bottom: 10px;
    }
}

.input-area {
    cursor: pointer;
    background-color: var(--box-background);
    color: var(--text-color);
    width: 100%;
    border: 2px dashed var(--border-color);
    border-radius: var(--border-radius);
    margin: auto;
    height: min-content;
    padding: 10px;
    transition: all 0.3s ease;
    max-width: 300px;

    &:hover {
        border-color: var(--primary-color);
        
        .overlay {
            opacity: 1;
        }
    }

    &.has-image {
        padding: 0;
        border-style: solid;
    }

    .image-container {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;

        .current-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: var(--border-radius);
            background-color: var(--background-color);
            padding: 10px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            border-radius: var(--border-radius);

            svg {
                color: white;
                font-size: 30px;
                margin-bottom: 5px;
            }

            p {
                color: white;
                margin: 0;
            }
        }
    }

    .middle {
        justify-content: center;
        align-items: center;
        height: 180px;
        display: flex;
        flex-direction: column;

        svg {
            color: var(--grey-color);
            font-size: 50px;
            margin-bottom: 10px;
        }

        p {
            color: var(--grey-color);
            text-align: center;
            margin: 0;
        }
    }
}