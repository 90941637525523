.calendar-modal {
    display: flex;
    background-color: var(--background-color);
    border-radius: 8px;
    overflow: hidden;
    gap: 1rem;
    min-width: 800px;

    .date-options {
        width: 200px;
        padding: 0px;
        margin : 10px;

        .date-option {
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
                background-color: var(--hover-color);
            }

            &.selected {
                background-color: var(--primary-color);
                color:  var(--text-color);
            }
        }
    }
    
    .date-input {
        padding: 5px;
        background-color: var(--box-background);
        border-radius: var(--border-radius);
        color: var(--primary-color);
        margin-bottom: 10px;
    }

    .calendars-container {
        flex: 1;
        padding: 20px;
        
        .selected-range {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;

            .date-range {
                padding: 8px 16px;
                border: 1px solid var(--border-color);
                border-radius: 4px;
            }
        }

        .calendar-grids {
            display: flex;
            gap: 20px;

            .calendar-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                .arrow {
                    cursor: pointer;
                    transition: opacity 0.3s ease;
                    
                    &.disabled {
                        opacity: 0.3;
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }
            }

            .weekdays {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                text-align: center;
                margin-bottom: 10px;
            }

            .days-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                gap: 2px;
            }
        }

        
    }
  
}

.calendar-actions {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    justify-content: flex-end;
    width: min-content;
    margin-left: auto;

    .button {
        text-align: center;
    }
}
.calendar-day.empty {
    visibility: hidden;
}

.calendar-day {
    cursor: pointer;
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: var(--hover-color);
    }
    
    &.selected {
        background-color: var(--primary-color);
        color: white;
        z-index: 2;
    }
    
    &.in-range {
        background-color: var(--primary-color);
        border-radius: 0;
        
        &:hover {
            background-color: var(--primary-color);
        }
    }

    &.selected.range-start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.selected.range-end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
}
