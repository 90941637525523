@import '../../../index.scss';

.top-bar {
    display: flex; // Change to flex to align items
    align-items: center; // Center items vertically
    justify-content: space-between; // Space between breadcrumbs and title
    text-align: center;
    padding: 10px 20px;
    /* box background a little darker */
    background-color: var(--background-color);
    border-bottom: 1px solid var(--box-background);
    width: 100%;
    gap: 10px;
    
    .title-link {
        text-decoration: none;
    }

    
    .left-side{
        display: flex;
        align-items: center;
        gap: 20px;

        .date-picker-container{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    
    .right-side{
        display: flex;
        align-items: center;
        gap: 10px;
    }
}