.date-picker{
    
    .button-date {
        border-radius: var(--border-radius);
        border: 1px solid  var(--border-color);
        padding: 5px 10px;
        width: fit-content !important;
        background-color: var(--background-color);
        position: relative;
        display: flex;
        gap: 5px;
        
        &:hover{
            background-color: var(--background-color-hover);
        }
    
    }

    h5, svg{
        color: var(--text-color) !important;
        display: flex;
        align-items: center;
    }
    
    h5{
        margin-left: 0;
        margin: 0 !important;
    }
    svg{
        width: 20px;
        height: 20px;
        padding: 0 !important;
        margin: 0 !important;
    }
}